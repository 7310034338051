<template>
    <v-form ref="form">
        <v-row class="mt-4" v-if="!(value.metodo_criacao == 'MD')">
            <v-col cols="4" class="py-0">
                <v-text-field
                    v-model="buscaFilial"
                    class="input-button input-cinza"
                    label="Pesquisar"
                    outlined
                    dense
                    hide-details
                >
                    <template>
                        <v-icon slot="append" color="white">mdi-magnify</v-icon>
                    </template>
                </v-text-field>
            </v-col>
            <v-col cols="4" class="py-0" v-if="!disabledOrganicos">
                <v-autocomplete
                    label="Estado"
                    outlined
                    dense
                    multiple
                    v-model="filtro.estado"
                    :items="listaEstado"
                    class="checkFiliais input-cinza"
                    :menu-props="menuProps"
                >
                    <template v-slot:selection="{ item, index }">
                        <span
                            v-if="filtro.estado.length === 1 && index === 0"
                            >{{ item.text }}</span
                        >
                        <span
                            v-if="filtro.estado.length > 1 && index === 0"
                            class="grey--text caption mr-1"
                            >{{ filtro.estado.length }} selecionados</span
                        >
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="4" class="py-0" v-if="!disabledOrganicos">
                <v-autocomplete
                    label="Cidade"
                    outlined
                    dense
                    multiple
                    class="input-cinza"
                    v-model="filtro.cidade"
                    :items="listaCidade"
                    :menu-props="menuProps"
                >
                    <template v-slot:selection="{ item, index }">
                        <span
                            v-if="filtro.cidade.length === 1 && index === 0"
                            >{{ item.text }}</span
                        >
                        <span
                            v-if="filtro.cidade.length > 1 && index === 0"
                            class="grey--text caption mr-1"
                            >{{ filtro.cidade.length }} selecionados</span
                        >
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col
                cols="4"
                class="py-0"
                v-if="disabledOrganicos && (acordo.idAcordos || []).length > 0"
            >
                <b
                    >Clusters da criação deste acordo ({{
                        acordo.idAcordos[0]
                    }}): </b
                >{{ clustersAcordoName }}
            </v-col>

            <v-col cols="4" class="py-0">
                <v-autocomplete
                    label="Cluster"
                    outlined
                    dense
                    multiple
                    class="input-cinza"
                    v-model="filtro.id_cluster"
                    :items="listaCluster"
                    item-value="value"
                    item-text="text_concat"
                    :menu-props="menuProps"
                >
                    <template v-slot:selection="{ item, index }">
                        <span
                            v-if="filtro.id_cluster.length === 1 && index === 0"
                            >{{ item.text }}</span
                        >
                        <span
                            v-if="filtro.id_cluster.length > 1 && index === 0"
                            class="grey--text caption mr-1"
                            >{{ filtro.id_cluster.length }} selecionados</span
                        >
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="4" class="py-0" v-if="!disabledOrganicos">
                <v-autocomplete
                    label="Tamanho"
                    outlined
                    dense
                    multiple
                    class="input-cinza"
                    v-model="filtro.tamanho"
                    :items="listaTamanho"
                    :menu-props="menuProps"
                >
                    <template v-slot:selection="{ item, index }">
                        <span
                            v-if="filtro.tamanho.length === 1 && index === 0"
                            >{{ item.text }}</span
                        >
                        <span
                            v-if="filtro.tamanho.length > 1 && index === 0"
                            class="grey--text caption mr-1"
                            >{{ filtro.tamanho.length }} selecionados</span
                        >
                    </template>
                </v-autocomplete>
            </v-col>
            <v-col cols="4" align="end" class="py-0">
                <v-btn
                    class="new-button px-5"
                    color="yellow"
                    dark
                    @click="limpar()"
                >
                    <v-icon left>mdi-filter-off</v-icon> Limpar
                </v-btn>
                <v-btn
                    class="ml-4 new-button px-5"
                    color="primary"
                    @click="filtrar()"
                >
                    <v-icon left>mdi-filter</v-icon> Filtrar
                </v-btn>
            </v-col>
        </v-row>
        <!-- <v-row>
			<v-col cols="4"><FilialLegendas /> </v-col>
		</v-row> -->

        <v-data-table
            :headers="listaHeader"
            :items="listaLojas"
            :search="buscaFilial"
            item-key="id_filial"
            :items-per-page="5"
            :footer-props="{
                'items-per-page-options': [5, 10, 50, 100, 500, -1],
                itemsPerPageText: 'Linhas por páginas',
                ofText: 'de',
                pageText: '{0}-{1} de {2}',
            }"
            :options.sync="pagination"
            class="tabela-filial"
            v-if="!( value.metodo_criacao == 'MD')"
        >
            <template v-slot:header="{}">
                <thead>
                    <tr>
                        <th colspan="4">
                            <div class="d-flex align-center">
                                <p class="text-filtro mr-3">
                                    Filtrar somente lojas com oportunidade
                                </p>
                                <v-switch
                                    v-model="filtro.filter_lojas_op"
                                    label=""
									class="mt-0"
                                />
                            </div>
                        </th>

                        <th colspan="3">
                            <FilialLegendas :organicos="disabledOrganicos" />
                        </th>

                        <th
                            v-for="p in getPeriodo()"
                            :key="p"
                            class="
                                v-data-table-header
                                text-center
                                th-separador
                                top-header
                            "
                        >
                            <div
                                style="
                                    width: 24px;
                                    margin-left: auto;
                                    margin-right: auto;
                                    display: block;
                                "
                                class="text-center"
                            >
                                <v-checkbox
                                    :key="p"
                                    v-model="todasFiliais[p]"
                                    @click="selecionaTudo(p, acordo.fnEditar)"
                                    color="#fff"
                                    dark
                                    class="ma-0 pa-0"
                                    :disabled="disabledOrganicos"
                                    hide-details
                                />
                            </div>
                        </th>
                    </tr>
                </thead>
            </template>
            <template v-slot:[`item.oportunidade_loja`]="{ item }">
                <v-chip
                    class="ma-2"
                    :color="item.oportunidade_loja ? 'green' : 'red'"
                    label
                    text-color="white"
                >
                    {{ item.oportunidade_loja ? "SIM" : "NÃO" }}
                </v-chip>
            </template>
            <template
                v-for="periodo in listaPeriodo"
                v-slot:[`item.quantidade${periodo}`]="{ item }"
            >
                <v-simple-checkbox
                    :key="periodo"
                    :ripple="false"
                    :disabled="item['oportunidade' + periodo] == false"
                    :value="item['quantidade' + periodo]"
                    :off-icon="iconOff(periodo, item).icon"
                    :title="iconOff(periodo, item).title"
					
                    :color="
                        !item['orig_quantidade' + periodo] &&
                        acordo.fnEditar &&
                        disabledOrganicos
                            ? 'primary'
                            : ''
                    "
                    @input="checkFilial(item, `quantidade${periodo}`, $event)"
                    @click="alteraFilial(item)"
                />
            </template>
        </v-data-table>

        <v-row no-gutters class="mb-3 mt-1">
            <v-col cols="12" class="px-2 ml-auto" align="end">
                <v-btn
                    class="new-button px-5 mx-2"
                    color="error"
                    :disabled="disabledOrganicos"
                    @click="limpaSelecionadas(true)"
                >
                    <v-icon left>mdi-trash-can</v-icon> Limpar Filiais 
                </v-btn>
                <v-btn
                    class="new-button px-5 mx-2"
                    color="primary"
                    :disabled="disabledOrganicos"
                    @click="abrirImportacao()"
                >
                    <v-icon left>mdi-upload</v-icon> Importar Base de Filiais
                </v-btn>
                <v-btn
                    class="new-button px-5 mx-2"
                    color="primary"
                    @click="carregarSelecionadas()"
                    v-if="!(value.metodo_criacao == 'MD')"
                >
                    <v-icon left>mdi-arrow-up</v-icon> Carregar Filiais Salvas
                </v-btn>
                <v-btn
                    class="new-button px-5 ml-2"
                    color="primary"
                    @click="salvarSelecionadas()"
                    v-if="!(value.metodo_criacao == 'MD')"
                >
                    <v-icon left>mdi-arrow-down</v-icon> Salvar Filiais
                    Selecionadas
                </v-btn>
            </v-col>
        </v-row>

        <v-data-table
            :headers="listaHeaderFiliais"
            :items="somaFiliais"
            item-key="nome"
            class="elevation-0 tabela"
            hide-default-footer
            disable-sort
        >
        </v-data-table>
        <div v-if="dialogoCamposFaltando" class="dialogo-erro" width="700px">
            <v-card class="br-15">
                <v-toolbar
                    elevation="0"
                    dark
                    class="error white--text fix-flex"
                >
                    <v-toolbar-title>
                        Por favor retorne as etapas anteriores e preencha os
                        seguintes campos:</v-toolbar-title
                    >
                </v-toolbar>
                <v-card-text class="pa-5">
                    <v-list>
                        <v-list-item
                            v-for="campo in camposFaltando"
                            :key="campo"
                        >
                            <v-list-item-content>
                                <v-list-item-title>{{
                                    campo
                                }}</v-list-item-title>
                            </v-list-item-content>
                        </v-list-item>
                    </v-list>
                </v-card-text>
            </v-card>
        </div>
        <v-overlay :value="carregando" :z-index="300">
            <v-progress-circular indeterminate size="64"></v-progress-circular>
        </v-overlay>

        <importar-filiais
            ref="importarFiliais"
            @importar="importar"
            @exportarModelo="exportarModelo"
        ></importar-filiais>

        <v-overlay
            :value="semAcesso"
            :absolute="true"
            :opacity="0.1"
            :z-index="3"
        ></v-overlay>
        <v-overlay
            :value="mostraOverlay"
            :absolute="true"
            :opacity="0.6"
            :z-index="3"
        ></v-overlay>

    </v-form>
</template>

<script>
import importarFiliais from "./importarFiliais.vue";
import FilialLegendas from "./_components/FilialLegendas.vue";
// import PontaTematicas from "./_components/PontasTematicas.vue";

export default {
    name: "AcordoFilial",
    inject: ["listaCampos"],
    components: {
        importarFiliais,
        FilialLegendas,
    },
    props: ["value"],

    data() {
        return {
            carregando: false,
            pagination: {},
            dialogoCamposFaltando: false,
            lista: {
                bandeiraRegiao: [],
                bandeiraEstado: [],
                bandeiraRegiaoCidade: [],
                bandeiraEstadoCidade: [],
                bandeiraCluster: [],
                bandeiraTamanho: [],
                check_out: [],
                espaco: [],
            },
            acordo: {},
            filtro: {
                estado: [],
                cidade: [],
                id_cluster: [],
                tamanho: [],
                check_out: [],
                filter_lojas_op: false,
            },
            preco_fixo: [],
            dados: [],
            dadosTemp: [],
            buscaFilial: "",
            filiais: [],
            filiaisSalvas: [],
            filiaisSemRegiao: [],
            errosFiliais: [],
            todasFiliais: [],
            somaFiliais: [],
            somaFiliaisValor: [],
            semAcesso: false,
            clustersAcordoName: "",
            menuProps: {
                bottom: true,
                offsetY: true,
                contentClass: "select-outline",
                transition: "slide-y-transition",
            },
        };
    },
    async created() {
        var lista = await this.listaCampos;
        // this.lista.bandeiraRegiao = lista.bandeiraRegiao;
        this.lista.bandeiraEstado = lista.bandeiraEstado;
        // this.lista.bandeiraRegiaoCidade = lista.bandeiraRegiaoCidade;
        this.lista.bandeiraEstadoCidade = lista.bandeiraEstadoCidade;
        this.lista.bandeiraCluster = lista.bandeiraCluster;
        this.lista.bandeiraTamanho = lista.bandeiraTamanho;
        this.lista.check_out = lista.check_out;
        this.lista.espaco = lista.espaco;
        this.lista.cluster = lista.cluster
    },
    computed: {
        disabledOrganicos() {
            return !this.value.flag_teste && this.espacoOrganico() > 0;
        },
        listaPeriodo() {
            return this.getPeriodo("");
        },
        listaRegiao() {
            return this.lista.bandeiraRegiao.filter(
                (obj) => obj.bandeira == this.value.bandeira
            );
        },
        listaEstado() {
            return this.lista.bandeiraEstado.filter(
                (obj) => obj.bandeira == this.value.bandeira
            );
        },
        listaCidade() {
            return this.lista.bandeiraEstadoCidade.filter(
                (obj) =>
                    obj.bandeira == this.value.bandeira &&
                    ((this.filtro.estado.length > 0 &&
                        this.filtro.estado.includes(obj.estado)) ||
                        this.filtro.estado.length == 0)
            );
        },
        listaCluster() {
            return this.lista.bandeiraCluster.filter(
                (obj) => obj.bandeira == this.value.bandeira
            );
        },
        listaTamanho() {
            return this.lista.bandeiraTamanho
                .filter(
                    (obj) =>
                        obj.bandeira == this.value.bandeira &&
                        (obj.text || "").trim() != ""
                )
                .map((e) => ({
                    bandeira: e.bandeira,
                    text: e.text.toUpperCase(),
                }));
        },
        listaHeader() {
            var header = [
                {
                    text: "ID JAVA",
                    value: "id_java",
                    align: "center",
                    class: "th-separador th-id-java",
                },
                {
                    text: "Estado",
                    value: "estado",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Cidade",
                    value: "cidade",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Cluster Acordo",
                    value: "cluster_acordo",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Cluster Filial",
                    value: "cluster",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Tamanho",
                    value: "tamanho",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Check-out",
                    value: "check_out",
                    align: "center",
                    class: "th-separador",
                }
            ];

            var mesAtual = new Date(this.value.mesInicial + "-15");
            var mesFinal = new Date(this.value.mesFinal + "-15");

            while (mesAtual <= mesFinal) {
                header.push({
                    text:
                        ("0" + (mesAtual.getMonth() + 1)).slice(-2) +
                        "/" +
                        mesAtual.getFullYear(),
                    value:
                        "quantidade_" +
                        mesAtual.getFullYear() +
                        "_" +
                        (mesAtual.getMonth() + 1),
                    align: "center",
                    class: "th-separador",
                });

                mesAtual.setMonth(mesAtual.getMonth() + 1);
            }

            return header;
        },
        listaHeaderFiliais() {
            var header = [
                {
                    text: "",
                    value: "nome",
                    align: "center",
                    class: "th-separador",
                },
                {
                    text: "Total",
                    value: "total",
                    align: "center",
                    class: "th-separador",
                },
            ];

            var mesAtual = new Date(this.value.mesInicial + "-15");
            var mesFinal = new Date(this.value.mesFinal + "-15");

            while (mesAtual <= mesFinal) {

                if(this.value.metodo_criacao == 'MD'){
                    for (const movelDermo of this.value.moveisDermo) {
                    header.push({
                        text: `${this.$moment(mesAtual).format("MM/YYYY")} - ${movelDermo.text}`,
                        value:
                            "quantidade_" +
                            mesAtual.getFullYear() +
                            "_" +
                            (mesAtual.getMonth() + 1) + "_" + movelDermo.id_espaco,
                        align: "center",
                        class: "th-separador",
                    });

                    }
                    
                }else {
                    header.push({
                        text:
                            ("0" + (mesAtual.getMonth() + 1)).slice(-2) +
                            "/" +
                            mesAtual.getFullYear(),
                        value:
                            "quantidade_" +
                            mesAtual.getFullYear() +
                            "_" +
                            (mesAtual.getMonth() + 1),
                        align: "center",
                        class: "th-separador",
                    });

                    }
                mesAtual.setMonth(mesAtual.getMonth() + 1);
            }

            return header;
        },
        listaLojas() {
            //let _dados = this.$helpers.cloneObj(this.dados);
            let _filter = !this.filtro.filter_lojas_op
                ? this.dados
                : this.dados.filter(
                      (e) => e.oportunidade_loja == this.filtro.filter_lojas_op
                  );
            return _filter;
        },
        camposFaltando() {
            const campos = [];
            if (!this.value.id_fornecedor) {
                campos.push("Fornecedor");
            }
            if (!this.value.mesInicial) {
                campos.push("Mês Inicial");
            }
            if (!this.value.mesFinal) {
                campos.push("Mês Final");
            }
            if (!this.value.id_espaco) {
                campos.push("Espaço");
            }
            if (!this.value.bandeira) {
                campos.push("Bandeira");
            }
            if(this.value.metodo_criacao == 'MD'){
                if (!this.value.marca) {
                    campos.push("Marca");
                }
            }

            return campos;
        },
        mostraOverlay() {
            if (this.camposFaltando.length > 0) {
                return true;
            }
            return false;
        },
    },
    methods: {
        iconOff(periodo, item) {
            const _isDisable = item["oportunidade" + periodo] == false;
            const _inventario = item["inventario" + periodo];
            const _compOrganico = item["comprometido_organico" + periodo];
            var _result = {
                icon: "mdi-checkbox-blank-outline",
                title: "",
            };

            switch (true) {
                //Não tem inventário
                case _isDisable && _inventario == 0:
                    _result = {
                        icon: "mdi-minus-box-outline",
                        title: "Não possui inventário",
                    };
                    break;
                //Não tem oportunidade
                case _isDisable && _inventario > 0:
                    _result = {
                        icon: "mdi-checkbox-blank-off-outline",
                        title:
                            `Não possui oportunidade` +
                            (_compOrganico > 0
                                ? ` , cluster alocado no ID Acordo ${_compOrganico}`
                                : ""),
                    };
                    break;
            }

            return _result;
        },
        checkFilial(item, key, value) {
            if (!this.disabledOrganicos) item[key] = value;

            if (this.disabledOrganicos /* && this.value.fnEditar */) {
                this.$dialog.notify.warning(
                    `Não é permitido editar filial no espaço orgânico que não seja um acordo de teste, caso queira incluir
					 ou retirar filial, filtre os clusters que deseja para o acordo e clique na opção "SALVAR FILIAIS SELECIONADAS". 
					`,
                    {
                        position: "top-right",
                        timeout: 15000,
                    }
                );
            }
        },
       async setAcordo(preco_fixo) {
            this.preco_fixo = preco_fixo

            if (this.camposFaltando.length > 0) {
                this.dialogoCamposFaltando = true;
			
                return;
            }
            this.dialogoCamposFaltando = false;

            if (this.dados.length === 0) {
				await this.filtrar(true, true);
			
            }
        },
        setSemAcesso(semAcesso) {
            this.semAcesso = semAcesso;
        },
        getPeriodo(campo = "quantidade") {
            var mesAtual = new Date(this.value.mesInicial + "-15");
            var mesFinal = new Date(this.value.mesFinal + "-15");

            var periodo = [];

            while (mesAtual <= mesFinal) {
                periodo.push(
                    campo +
                        "_" +
                        mesAtual.getFullYear() +
                        "_" +
                        (mesAtual.getMonth() + 1)
                );

                mesAtual.setMonth(mesAtual.getMonth() + 1);
            }

            return periodo;
        },
        limpaFiltro() {
            this.filtro = {
                estado: [],
                cidade: [],
                id_cluster: [],
                tamanho: [],
                check_out: [],
                filter_lojas_op: false,
            };
            this.buscaFilial = "";
        },
        async limpar() {
            this.limpaFiltro();

            await this.filtrar();
        },
        async filtrar(marcaOrganico = true, created = false) {
            this.carregando = !this.carregando;

            var data = {};

            data.filtro = this.filtro;
            data.filtro.bandeira = [this.value.bandeira];
            data.filtro.check_out =
                this.value.modelo_ck != null ? [this.value.modelo_ck] : [];

            data.acordo = this.value;

            this.dados = []

            if (this.value.metodo_criacao == 'MD') {
                for (var movelDermo of this.value.moveisDermo) {
                    data.acordo.id_espaco = movelDermo.id_espaco
                    this.dados = [
                        ...this.dados,
                        ...await this.genDados(data,created)
                    ]    
                }
                if(this.value.fnLeitura || this.value.fnEditar){

                    await this.carregarFiliaisMDEdicao(data, created);

                }

            } else {
                this.dados = await this.genDados(data,created)
                for (const e of this.filiais) {
                    var index = this.dados.findIndex(
                        (element) => element.id_filial == e.id_filial
                    );
    
                    if (index >= 0) {
                        this.dados.splice(index, 1, { ...e });
                    }
                }
    
                for (const e of this.getPeriodo()) {
                    this.todasFiliais[e] = false;
                }
    
                if (this.value.fnEditar && this.filiais.length == 0) {
                    for (const dado of this.dados) {
                        dado.alterado = 1;
                        dado.comprometido = 0;
    
                        for (const e of this.getPeriodo()) {
                            if (dado[e]) {
                                dado.comprometido = 1;
                            }
                        }
                    }
    
                    if (created) this.salvarSelecionadas(true);
                }
    
                if (
                    this.espacoOrganico() &&
                    marcaOrganico &&
                    !this.value.fnLeitura &&
                    (!this.value.fnEditar ||
                        (this.value.fnEditar && this.disabledOrganicos))
                ) {
                    for (const e of this.getPeriodo()) {
                        this.todasFiliais[e] = true;
                        this.selecionaTudo(e, true);
                    }
                }

            }    

            this.pagination.page = 1;

            this.carregando = !this.carregando;
        },
        async genDados (data, created = false) {

            let _dados = []
            let response = []
            
            if (this.value.ponta_tematica) {
				response = await this.$http().post("/acordo/filiaisPorTematicas", {
					data: data,
				});
			}
            //  else if(this.value.metodo_criacao == 'MD'){
            //     response = await this.$http().post("/acordo/modeloFiliaisMoveisDermo", {
            //         data: data,
            //     });
                
            //     await this.carregarFiliaisMDEdicao(response.data, created);
            //     return;

            // } 
            
            else {
				response = await this.$http().post("/acordo/filiais", {
					data: data,
				});
			}

            if (created && this.disabledOrganicos)
                this.filtro.id_cluster = this.value.clusters || [];

            const _dadosConverte = response.data.map((e) => {
                let _op =
                    this.getPeriodo("").filter(
                        (m) => e["oportunidade" + m] > 0 && !e["quantidade" + m]
                    ).length > 0;
                let _qtd =
                    this.getPeriodo("").filter(
                        (m) =>
                            e["quantidade" + m] &&
                            !this.filtro.id_cluster.includes(e.id_cluster) &&
                            !this.filtro.id_cluster.includes(
                                e.id_cluster_acordo
                            )
                    ).length > 0;
                for (var mes of this.getPeriodo()) {
                    e["orig_" + mes] = this.$helpers.cloneObj(e[mes]);
                }
                return {
                    ...e,
                    oportunidade_loja: _op,
                    comprometido_loja_foracluster: _qtd,
                };
            });

            _dados = _dadosConverte.filter(
                (e) =>
                    this.filtro.id_cluster.includes(e.id_cluster) ||
                    this.filtro.id_cluster.includes(e.id_cluster_acordo) ||
                    this.filtro.id_cluster.length == 0 ||
                    e.comprometido_loja_foracluster
            );

            if (created) {
                var _clustersAcordoName = new Set();

                for (var i of this.listaCluster) {
                    var _countCompEdicao = 0;
                    let _count = _dadosConverte.filter((e) => {
                        var _op = false;
                        var _ck = false;
                        for (const m of this.getPeriodo("")) {
                            if (
                                e["oportunidade" + m] > 0 &&
                                !e["quantidade" + m]
                            ) {
                                _op = true;
                            }

                            if (
                                e["quantidade" + m] &&
                                e.id_cluster == i.value &&
                                !_ck
                            ) {
                                _countCompEdicao++;
                                _ck = true;
                            }
                        }

                        return e.id_cluster == i.value && _op;
                    }).length;

                    i.text_concat = `
						${i.text} - (Filiais - ${_count} disponíveis
						${_countCompEdicao > 0 ? "/ " + _countCompEdicao + " neste acordo" : ""})
					`;

                    if ((this.value.clusters_acordo || []).includes(i.value))
                        _clustersAcordoName.add(i.text);

                    /* if (
						created &&
						this.disabledOrganicos &&
						_countCompEdicao > 0
					) {
						this.filtro.id_cluster.push(i.value);
					} */
                }

                this.clustersAcordoName = [..._clustersAcordoName].join("/");

            }

            return _dados


        },
        selecionaTudo(mes, force = false) {
            if (this.disabledOrganicos && !force) return;

            for (const e of this.dados) {
                if (e[mes.replace("quantidade", "oportunidade")] * 1 > 0) {
                    e[mes] = this.todasFiliais[mes] ? true : false;
                    e.alterado = 1;
                    e.comprometido = 0;

                    for (const p of this.getPeriodo()) {
                        if (e[p]) {
                            e.comprometido = 1;
                        }
                    }
                }
            }
        },
        alteraFilial(item) {
            if (this.disabledOrganicos) return;

            var dado = this.dados.find(
                (element) => element.id_filial == item.id_filial
            );

            dado.alterado = 1;
            dado.comprometido = 0;

            for (const e of this.getPeriodo()) {
                if (dado[e]) {
                    dado.comprometido = 1;
                }
            }
        },
        getCusto(mes, arrayCusto = []) {
            const _mesArray = mes.replace("quantidade_", "").split("_");
            const _mes =
                _mesArray[0] +
                "-" +
                (_mesArray[1] * 1 < 10 ? "0" + _mesArray[1] : _mesArray[1]) +
                "-01";

            var _custo = arrayCusto.filter((e) => e.mes == _mes);

            return _custo.length > 0 ? _custo[0].custo : 0;
        },
        disableCheckCusto(mes, arrayCusto = []) {
            const _mesArray = mes.split("_");
            const _mes =
                _mesArray[1] +
                "-" +
                (_mesArray[2] * 1 < 10 ? "0" + _mesArray[2] : _mesArray[2]) +
                "-01";

            var _custo = arrayCusto.filter((e) => e.mes == _mes);

            return _custo.length == 0;
        },
        async salvarSelecionadas(force = false) {

			if(!force){
				this.$emit("limpaDescontos");
			}

            var alterados = this.dados.filter(
                (element) => element.alterado == 1
            );

            var _filiais = !this.disabledOrganicos
                ? this.$helpers.cloneObj(this.filiais)
                : [];

            if (alterados.length == 0 && this.disabledOrganicos) {
                return;
            }

            for (const e of alterados) {
                var index = _filiais.findIndex(
                    (element) => element.id_filial == e.id_filial && element.id_espaco == e.id_espaco
                );

                if (
                    !force &&
                    !this.value.flag_teste &&
                    this.disabledOrganicos
                ) {
                    for (const p of this.getPeriodo()) {
                        let _mes = p.replace("quantidade", "");
                        e[p] = e["comprometido_organico" + _mes] == 0;
                    }
                }

                if (index >= 0) {
                    if (e.comprometido) {
                        _filiais.splice(index, 1, { ...e });
                    } else {
                        _filiais.splice(index, 1);
                    }
                } else {
                    if (e.comprometido) {
                        _filiais.push({ ...e });
                    }
                }

                e.alterado = 0;
            }

            var soma = [];
            const _clusters = new Set();
            soma[0] = {};
            soma[0].total = 0;
            soma[1] = {};
            soma[1].nome = "Custos";
            soma[1].total = 0;
            let _periodo = this.getPeriodo() 
            for (const p of _periodo) {
                soma[0][p] = 0;
                soma[1][p] = 0;
            }
            var _filiaisSemRegiao = []
            var _regioesSelecionadas = []
            for (const e of _filiais) {
                if ((!e.regiao) || (e.regiao.length == 0)) _filiaisSemRegiao.push(e)
                if (!_regioesSelecionadas.includes(e.regiao)) _regioesSelecionadas.push(e.regiao)
                var fnFilial = 0;

                for (const p of _periodo) {
                    var id_espaco = '';
                    if (this.value.metodo_criacao == 'MD') {
                        id_espaco = `_${e.id_espaco}`
                    } 
                    if(!soma[0][`${p}${id_espaco}`]) soma[0][`${p}${id_espaco}`] = 0;

                    soma[0][p] += e[p] * 1;
                    if (this.value.metodo_criacao == 'MD') {
                        soma[0][`${p}${id_espaco}`] += e[p] * 1;
                    } 
                    if (e[p]) {
                        fnFilial = 1;
                    }
                    if (e[p]) {
                        if(!soma[1][`${p}${id_espaco}`]) soma[1][`${p}${id_espaco}`] = 0;

                        let _custo = this.getCusto(p, e.custo);
                        if (this.value.metodo_criacao == 'MD') {
                            soma[1][`${p}${id_espaco}`] += _custo * 1;
                        } 
                        soma[1][p] += _custo * 1;
                        soma[1].total += _custo * 1;
                    }
                }
                if (fnFilial > 0) _clusters.add(e.cluster);
                soma[0].total += fnFilial;
            }



            if (this.value.mecanismo_preco_id == 3) {
                for (const f of this.preco_fixo) {
                    soma[1]['quantidade_' + f.mes.replace('-','_')] = parseFloat(f.preco_fixo)
                }
                soma[1].total = this.preco_fixo.reduce((a,e) => {
                    return a += parseFloat(e.preco_fixo)
                },0)
            }
            
            const _periodoMes = _periodo.map(e => {
                return e.replace('quantidade_','').replace('_','-') + '-01'
            })
            if (this.value.mecanismo_preco_id == 2) {
                var _custofixado = await this.$http().post("/acordo/custofixado", {
                    data: {
                        id_espaco: this.value.id_espaco,
                        clusters: [..._clusters],
                        periodo: _periodoMes
                    },
                });
                for (const f of _custofixado.data) {
                    soma[1]['quantidade_' + f.mes.replace('-','_')] = parseFloat(f.custo)
                }
                soma[1].total = _custofixado.data.reduce((a,e) => {
                    return a += parseFloat(e.custo)
                },0)
            }
            this.somaFiliaisValor = { ...soma[1] };



            soma[1].total = soma[1].total.toLocaleString("pt-br", {
                style: "currency",
                currency: "BRL",
            });
            for (const p of this.getPeriodo()) {

                if(this.value.metodo_criacao == 'MD'){
                    for (const movelDermo of this.value.moveisDermo) {
                        soma[1][`${p}_${movelDermo.id_espaco}`] = (soma[1][`${p}_${movelDermo.id_espaco}`] || 0).toLocaleString("pt-br", {
                            style: "currency",
                            currency: "BRL",
                        });
                    }
                } 
                    soma[1][p] = soma[1][p].toLocaleString("pt-br", {
                        style: "currency",
                        currency: "BRL",
                    });
                
            }

            soma[0].nome = `Filiais Salvas (${[..._clusters].join(" / ")})`;

            this.somaFiliais = soma;
            this.filiais = _filiais;
            this.filiaisSemRegiao = _filiaisSemRegiao
            this.value.regioesSelecionadas = _regioesSelecionadas
            this.value.regioesClusters = []
            this.value.clusters = [
                ...new Set(_filiais.map((e) => e.id_cluster)),
            ];
            // this.value.clustersNomes = [
            //     //...new Set(_filiais.map((e) => e.cluster)),

            // ];
            // let clusterNomes = []
            // for(var e of _filiais) {
            //     this.value.clustersNomes[e.id_cluster] = e.cluster
            // }
            // let clusterNomes = this.value.clusters.map(item => {
            //     return this.listaCampos.cluster.filter(b => (b.value == item)).map(c => ({
            //         id:c.value, nome: c.text
            //     }))
            // })
            // console.log(this)
            // const listaCampos = await this.listaCampos
            // console.log('clusterNomes init', listaCampos.clusters)
            let clusterNomes = []
            for (var a of this.value.clusters) {
                for (var b of this.lista.cluster) {
                    if (a == b.value) {
                        clusterNomes.push({id:a,nome:b.text})
                    }
                }
            }
            console.log('clusterNomes', clusterNomes)
            var existe = false
            for (var regiao of _regioesSelecionadas) {
                for (var cluster of clusterNomes) {
                    existe = false
                    for(var filial of _filiais) {
                        if (regiao == filial.regiao && cluster.id == filial.id_cluster) existe = true
                    }
                    if (existe) this.value.regioesClusters.push(regiao + '_' + cluster.nome)
                }               
            }
            console.log('this.value.regioesClusters',this.value.regioesClusters)
            this.value.clustersNomes = clusterNomes
            // this.value.filiais = _filiais;
            //this.filiaisSalvas = _filiais;
            // this.listaCampos.cluster.then(p => {
            //     console.log(p)
            // })
        },
        carregarSelecionadas() {
            this.dados = this.$helpers.cloneObj(this.filiais);
            this.pagination.page = 1;
        },
        limpaSelecionadas(fnServidor = false) {
            if (this.disabledOrganicos && fnServidor) return;

            for (const e of this.getPeriodo()) {
                this.todasFiliais[e] = false;
            }

            this.dados = [];
            this.filiais = [];
            this.somaFiliais = [];

            this.limpaFiltro();

            if (fnServidor) {
                //this.limpaFiltro();

                this.filtrar(false);
            }
        },
        espacoOrganico() {
            if (this.value.id_espaco) {
                var espaco = this.lista.espaco.filter(
                    (obj) => obj.value == this.value.id_espaco
                );

                return espaco[0].organico;
            } else {
                return 0;
            }
        },
        async abrirImportacao() {
            if (this.disabledOrganicos) return;

            this.limpaFiltro();

            await this.filtrar(false);

            this.$refs.importarFiliais.abrir(this.value, this.dados);
        },
        async carregarFiliaisMDEdicao(data){
            this.dadosTemp = this.dados.map(a => ({...a}))
            data.idAcordosLote = this.value.idAcordosLote

            const   response = await this.$http().post("/acordo/modeloFiliaisMoveisDermo", {
                data: data,
            });

             for (const e of response.data ){
                let espacosModelDermo = []
              
                    espacosModelDermo = this.value.moveisDermo.map(item => {
                        return item.id_espaco
                    })
                
                    var index = 0;

                for (var espacoDermo of espacosModelDermo) {

                    index = this.dados.findIndex(element => element.id_java == e.id_java && ((espacoDermo !== '') && (element.id_espaco == espacoDermo)))

                    let prefixo = (espacoDermo !== '') ? espacoDermo + '_' : ''                       
                    debugger;
                    if(index >= 0){
                    
                        this.dadosTemp[index].comprometido = 0
                        this.dadosTemp[index].alterado = 1

                        for (const campo of this.getPeriodo('')) {


                            if(e[prefixo + 'qtde'+campo] == 1 ){
                            
                                if(this.dados[index]['oportunidade'+campo]*1 > 0) {
                                    
                                    this.dadosTemp[index]['quantidade'+campo] = true
                                    this.dadosTemp[index].comprometido = 1
                                }
                                else {

                                    console.log({
                                        msg: `A oportunidade não é maior que zero para a filial (id_java = ${e.id_java}), para o campo quantidade${campo}.`                                
                                    })
                                }
                            }
                            else{

                                this.dadosTemp[index]['quantidade'+campo] = false
                            }
                        }
                    }
                    else{

                        console.log({
                            msg: `A filial (id_java = ${e.id_java}) não foi encontrada.`                                
                        })
                    }
                }
            }
            this.limpaSelecionadas();

            this.dados = this.dadosTemp //.filter(e => e.comprometido == 1)
            this.salvarSelecionadas();

        },
        async importar() {
            this.limpaSelecionadas();

            this.dados = this.$refs.importarFiliais.dados;

            this.salvarSelecionadas();
        },
        async exportarModelo() {
            this.$refs.importarFiliais.carregando = true;

            var data = {};

            data.filtro = this.filtro;
            data.filtro.bandeira = [this.value.bandeira];

            data.acordo = this.value;

            var response = null

            if (this.value.metodo_criacao == 'MD') {
                data.idAcordosLote = this.value.idAcordosLote
                data.exportar = true;
                response = await this.$http().post("/acordo/modeloFiliaisMoveisDermo", {
                    data: data,
                });
                // window.location = this.$http("baseURL") + response.data.url;
            } else {
                response = await this.$http().post("/acordo/modeloFiliais", {
                    data: data,
                });
            }


            window.location = this.$http("baseURL") + response.data.url;

            this.$refs.importarFiliais.carregando = false;
        },

        validate() {
            this.errosFiliais = []
            if (!this.acordo.fnLeitura) {
                if (this.somaFiliais.length == 0) {
                    this.errosFiliais.push('erro_quantidade')
                    return false;
                } else {
                    for (const p of this.getPeriodo()) {
                        if (this.somaFiliais[0][p] == 0) {
                            this.errosFiliais.push('erro_quantidade')
                            return false;
                        }
                    }
                }
                if (this.value.metodo_criacao == 'RG' && this.filiaisSemRegiao.length > 0) {
                    this.errosFiliais.push('erro_regioes')
                    return false;
                }    
            }
            return true;
        },
    },
};
</script>

<style>
.theme--light.v-icon.v-icon.v-icon--disabled {
    color: rgba(0, 0, 0, 0.15) !important;
}

/* .custom-color-disable .theme--light.v-icon.v-icon.v-icon--disabled {
	color: rgba(244, 149, 7, 0.25) !important;
} */


.text-filtro {
    color: #000;
    font-size: 0.9rem;
    margin: 0 !important;
    align-self: center;
}
.top-header {
    background-color: #24796e;
}
.tabela-filial .v-data-table__wrapper {
    border-radius: 10px;
    border-right: 1px solid #ccc;
}
.th-id-java {
    border-radius: 10px 0 0 0;
}

.tabela-filial tr > td:first-child {
    border-left: 1px solid #ccc;
}
</style>