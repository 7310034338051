<template>
 <v-form >
   <v-menu
    v-model="menu1"
    :close-on-content-click="false"
    offset-y
    bottom
    width="0"
    content-class="select-outline"
    nudge-top="25px"
    transition="slide-y-transition"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        :placeholder="placeholder"
        v-bind:label="label"
        v-model="cmpValue"
        v-bind:rules="rules"
        :class="{
          select: true,
          'input-cinza': true,
          active: menu1,
        }"
        v-bind:required="required"
        v-bind:disabled="disabled"
        outlined
        dense
        v-bind="attrs"
        v-on="on"
        @click:clear="date = null"
        @change="handleChange"
      >
      </v-text-field>
    </template>
    <v-divider class="mt-0"></v-divider>
    <v-date-picker
      type="month"
      :min="min"
      :max="max"
      locale="pt-BR"
    :value="value"
      @change="handleChange"
      color="primary"
      class="input-mes"
      no-title
      width="auto"
    ></v-date-picker>
    <v-divider class="mt-0 mb-3"></v-divider>
    <v-row no-gutters>
      <v-col cols="6" class="pa-3">
        <v-btn class="w-100" color="yellow" dark @click="limpar()">
          <v-icon left>mdi-filter-off</v-icon> Limpar
        </v-btn>
      </v-col>
      <v-col cols="6" class="pa-3 pl-0">
        <v-btn class="w-100" color="primary" @click="mesAtual()">
          <v-icon left>mdi-calendar-check</v-icon> Mes Atual
        </v-btn>
      </v-col>
    </v-row>
  </v-menu>
 </v-form>
</template>

<script>
export default {
  name: "MesInput",
  props: [
    "value",
    "rules",
    "min",
    "max",
    "placeholder",
    "label",
    "required",
    "disabled",
    "change",
    "prepend-icon"
  ],
  data() {
    return {
      mes: null,
      menu1: false,
    };
  },
  computed: {
    cmpValue: {
      get: function () {
        return this.value !== null && this.value !== ""
          ? this.$moment(this.value, "YYYY-MM")
              .format("MMMM [de] YYYY")
              .capitalize()
          : "";
      },
      set: function (newValue) {
        this.$emit("input", newValue);
      },
    },
  },
  methods: {
    handleChange(valor) {
      if (this.change) {
        this.change(valor);
      }
      this.menu1 = false;
      this.cmpValue = valor;
    },
    limpar() {
      this.mes = null;
      this.$emit("input", null);
    },
    mesAtual() {
      const mes = this.$moment().format("YYYY-MM");
      this.cmpValue = mes
      this.menu1 = false;
      if (this.change) {
        this.change(mes);
      }
      
    },
  },
};
</script>

<style>
.input-cinza.active .v-input__slot {
  border-bottom: none;
}

.input-cinza.active fieldset legend {
  width: 0 !important;
}

.input-mes .v-date-picker-header__value button {
  font-weight: 500;
  color: var(--v-primary-base) !important;
}

.input-mes,
.input-mes .v-picker__body {
  width: 100% !important;
}
.input-mes .v-btn__content {
  text-transform: capitalize;
}
.input-mes input {
  text-transform: uppercase !important;
}
</style>